import { Component, Vue } from "vue-property-decorator";
import { ValidateIf, IsDefined, IsNotEmpty, MaxLength, validate } from "class-validator";

import { Header,Footer } from "@/layout";
import { APLightbox } from "@/components";
import { ErrorCode, ErrorMessage } from "@/constant";
import { QuizModel } from "@/models";
import { TeCommon } from "../Common";

export class QuestionForm {
    @IsDefined() @IsNotEmpty({ message: "必填" }) grade = "";
    @IsDefined() @IsNotEmpty({ message: "必填" }) unit = "";
    @IsDefined() @IsNotEmpty({ message: "必填" }) level = "";
    @IsDefined() @IsNotEmpty({ message: "必填" }) difficulty = "";
    @IsDefined() @IsNotEmpty({ message: "必填" }) tid = "";
    @IsDefined() @IsNotEmpty({ message: "必填" }) type = "1";
    @IsDefined() @IsNotEmpty({ message: "必填" }) @MaxLength(1000, { message: "最多輸入1000字" }) question = '';
    audio = '';
    pic = ''; //圖片需有初始值
    option!: { [key: string]: any };
    @ValidateIf(o => o.type === '1') @IsNotEmpty({ message: "必填" }) type1 = '1';
    @ValidateIf(o => o.type === '1' && o.type1 === '1') @IsNotEmpty({ message: "必填" }) pic1 = '';
    @ValidateIf(o => o.type === '1' && o.type1 === '2') @IsNotEmpty({ message: "必填" }) text1 = '';
    @ValidateIf(o => o.type === '1') @IsNotEmpty({ message: "必填" }) type2 = '1';
    @ValidateIf(o => o.type === '1' && o.type2 === '1') @IsNotEmpty({ message: "必填" }) pic2 = '';
    @ValidateIf(o => o.type === '1' && o.type2 === '2') @IsNotEmpty({ message: "必填" }) text2 = '';
    type3 = '1';
    pic3 = '';
    text3 = '';
    type4 = '1';
    pic4 = '';
    text4 = '';
    type5 = '1';
    pic5 = '';
    text5 = ''
    @ValidateIf(o => o.type === '2') @IsNotEmpty({ message: "必填" }) option1 = '';
    @ValidateIf(o => o.type === '2') @IsNotEmpty({ message: "必填" }) option2 = '';
    @IsDefined() @MaxLength(500, { message: "最多輸入500字" }) suggestAns = '';
    @ValidateIf(o => o.type === '1' || o.type === '2') @IsNotEmpty({ message: "必填" }) answer = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) active = "1";
    qqid = '';
}

type response = { [key: string]: unknown };

@Component<ApTeacherQuizQuestionAddController>({
    components: {
        Header, APLightbox,Footer
    }
})
export default class ApTeacherQuizQuestionAddController extends Vue {
    /*定義data 變數 */
    private doubleClick = false;
    private questionForm = new QuestionForm();
    private gradeList: object = {};
    private levelList: object = {};
    private unitList: response = {};
    private name = window.localStorage.getItem('teacher_name') as string;
    private token = window.localStorage.getItem('teacherToken') as string;

    private lightbox: { showLB: boolean, errorMsg: string } = {
        showLB: false, 
        errorMsg:  '',
    };

    private errorMsgMap: { [key: string]: string } = {
        grade: '',
        unit: '',
        level: '',
        difficulty: '',
        type: '',
        question: '',
        type1: '',
        pic1: '',
        text1: '',
        type2: '',
        pic2: '',
        text2: '',
        type3: '',
        pic3: '',
        text3: '',
        type4: '',
        pic4: '',
        text4: '',
        type5: '',
        pic5: '',
        text5: '',
        option1: '',
        option2: '',
        suggestAns: '',
        answer: '',
        active: '',
    };

    public async created() {
        const item: { [key: string]: string } = {
            qqid: this.$route.query.qqid as string,
            token: this.token,
        }
        const editData = await QuizModel.tGetQuestion(item) as response;

        this.assignDataToForm(editData);
        this.gradeList = await TeCommon.getGrade();
        const grade = this.questionForm.grade;
        this.levelList = await TeCommon.getLevel(grade);
        this.unitList = await TeCommon.getUnit();
    }

    private assignDataToForm(data: response) {
        TeCommon.checkToken(data.ERR_CODE as number);

        const item = data.info as {
            qqid: string;
            grade: string;
            unit: string;
            level: string;
            difficulty: string;
            tid: string;
            type: string;
            audio: string;
            question: string;
            pic: string;
            option: { [key: string]: any };
            type1: string,
            pic1: string,
            text1: string,
            type2: string,
            pic2: string,
            text2: string,
            type3: string,
            pic3: string,
            text3: string,
            type4: string,
            pic4: string,
            text4: string,
            type5: string,
            pic5: string,
            text5: string,
            option1: string;
            option2: string;
            suggestAns: string;
            answer: string;
            active: string;
        };
        this.questionForm.qqid = item.qqid;
        this.questionForm.grade = item.grade;
        this.questionForm.unit = item.unit;
        this.questionForm.level = item.level;
        this.questionForm.difficulty = item.difficulty;
        this.questionForm.tid = item.tid;
        this.questionForm.type = item.type;
        this.questionForm.audio = item.audio;
        this.questionForm.question = item.question;
        this.questionForm.pic = item.pic;
        this.questionForm.option = item.option;
        if (item.type === '1') {
            this.questionForm.type1 = typeof(item.option[1]) === 'undefined' ? '1' : item.option[1].type;
            this.questionForm.pic1 = typeof(item.option[1]) === 'undefined' ? '' : item.option[1].pic;
            this.questionForm.text1 = typeof(item.option[1]) === 'undefined' ? '' : item.option[1].text;
            this.questionForm.type2 = typeof(item.option[2]) === 'undefined' ? '1' : item.option[2].type;
            this.questionForm.pic2 = typeof(item.option[2]) === 'undefined' ? '' : item.option[2].pic;
            this.questionForm.text2 = typeof(item.option[2]) === 'undefined' ? '' : item.option[2].text;
            this.questionForm.type3 = typeof(item.option[3]) === 'undefined' ? '1' : item.option[3].type;
            this.questionForm.pic3 = typeof(item.option[3]) === 'undefined' ? '' : item.option[3].pic;
            this.questionForm.text3 = typeof(item.option[3]) === 'undefined' ? '' : item.option[3].text;
            this.questionForm.type4 = typeof(item.option[4]) === 'undefined' ? '1' : item.option[4].type;
            this.questionForm.pic4 = typeof(item.option[4]) === 'undefined' ? '' : item.option[4].pic;
            this.questionForm.text4 = typeof(item.option[4]) === 'undefined' ? '' : item.option[4].text;
            this.questionForm.type5 = typeof(item.option[5]) === 'undefined' ? '1' : item.option[5].type;
            this.questionForm.pic5 = typeof(item.option[5]) === 'undefined' ? '' : item.option[5].pic;
            this.questionForm.text5 = typeof(item.option[5]) === 'undefined' ? '' : item.option[5].text;
        } else if (item.type === '2') {
            this.questionForm.option1 = item.option[1];
            this.questionForm.option2 = item.option[2];
        }
        this.questionForm.suggestAns = item.suggestAns;
        this.questionForm.answer = item.answer;
        this.questionForm.active = item.active;
    }

    private async getLevel() {
        this.questionForm.level = '';
        this.questionForm.unit = '';
        const grade = this.questionForm.grade;
        this.levelList = await TeCommon.getLevel(grade);
        this.unitList = {};
    }

    private async getUnit() {
        this.questionForm.unit = '';
        this.unitList = await TeCommon.getUnit();
    }

    private previewfile(event: any) {
        const file = event.target.files[0];

        if (file) { //若有上傳
            /* 限制檔案上傳型別 */
            const suffixName = file.name.substring(file.name.lastIndexOf('.') + 1);   // 得到檔案字尾名
            if (suffixName !== 'mp3') {
                this.lightbox.errorMsg = '上傳檔案只能是 mp3 格式！';
                this.lightbox.showLB = true;
                return;
            }
            /* 限制檔案上傳大小 */
            const isLimitSize = file.size / 1024 / 1024 < 5;
            if (!isLimitSize) {
                this.lightbox.errorMsg = '上傳檔案不能超過 5MB！';
                this.lightbox.showLB = true;
                return;
            }
            const reader = new FileReader();

            reader.onload = (e: any) => {
                this.questionForm.audio = file.name;
            };
            reader.readAsDataURL(file);
        }
    }

    private previewPic(event: any, name: number) {
        const file = event.target.files[0];

        if (file) { //若有上傳圖片
            /* 限制檔案上傳型別 */
            const suffixName = file.name.substring(file.name.lastIndexOf('.') + 1);   // 得到檔案字尾名
            if (suffixName !== 'jpg' && suffixName !== 'png' && suffixName !== 'gif' && suffixName !== 'jpeg') {
                this.lightbox.errorMsg = '上傳檔案只能是 jpg、png、gif、jpeg 格式！';
                this.lightbox.showLB = true;
                return;
            }
            /* 限制檔案上傳大小 */
            const isLimitSize = file.size / 1024 / 1024 < 3;
            if (!isLimitSize) {
                this.lightbox.errorMsg = '上傳檔案不能超過 3MB！';
                this.lightbox.showLB = true;
                return;
            }
            const reader = new FileReader();

            reader.onload = (e: any) => {
                switch (name) {
                    case 1:
                        this.questionForm.pic1 = e.target.result;
                        break;
                    case 2:
                        this.questionForm.pic2 = e.target.result;
                        break;
                    case 3:
                        this.questionForm.pic3 = e.target.result;
                        break;
                    case 4:
                        this.questionForm.pic4 = e.target.result;
                        break;
                    case 5:
                        this.questionForm.pic5 = e.target.result;
                        break;
                    default:
                        this.questionForm.pic = e.target.result;
                        break;
                }
            };
            reader.readAsDataURL(file);
        }
    }

    private delPic(name: number) {
        switch (name) {
            case 1:
                this.questionForm.pic1 = '';
                break;
            case 2:
                this.questionForm.pic2 = '';
                break;
            case 3:
                this.questionForm.pic3 = '';
                break;
            case 4:
                this.questionForm.pic4 = '';
                break;
            case 5:
                this.questionForm.pic5 = '';
                break;
            default:
                this.questionForm.pic = '';
                break;
        }
    }

    private validateAndSubmit() {
        validate(this.questionForm, { skipMissingProperties: true }).then(
            async result => {
                if (result.length !== 0) {
                    this.errorMsgMap = { //先清空error message
                        grade: '',
                        unit: '',
                        level: '',
                        difficulty: '',
                        type: '',
                        question: '',
                        type1: '',
                        pic1: '',
                        text1: '',
                        type2: '',
                        pic2: '',
                        text2: '',
                        type3: '',
                        pic3: '',
                        text3: '',
                        type4: '',
                        pic4: '',
                        text4: '',
                        type5: '',
                        pic5: '',
                        text5: '',
                        option1: '',
                        option2: '',
                        suggestAns: '',
                        answer: '',
                        active: '',
                    };
                    result.forEach((item, index) => {
                        const error = item.constraints as {
                            [key: string]: string;
                        };
                        Object.keys(error).forEach(key => {
                            this.errorMsgMap[item.property] = error[key]; //放入error message
                        });
                    });
                    return;
                }

                if (!this.doubleClick) {
                    this.doubleClick = true;

                    const formobj: HTMLFormElement = document.getElementById('questionEdit') as HTMLFormElement;
                    const data = new FormData(formobj); //創建空的Formdata object

                    data.append('qqid', this.questionForm.qqid);
                    data.append('pic', this.questionForm.pic);
                    data.append('pic1', this.questionForm.pic1);
                    data.append('pic2', this.questionForm.pic2);
                    data.append('pic3', this.questionForm.pic3);
                    data.append('pic4', this.questionForm.pic4);
                    data.append('pic5', this.questionForm.pic5);
                    data.append('token', this.token);

                    const errNo = await QuizModel.teEditQuestion(data);
                    switch (errNo) {
                        case ErrorCode.InvalidToken:
                            TeCommon.logout();
                            break;
                        case ErrorCode.Success:
                            this.doubleClick = false;
                            this.$router.push("/teacher/quiz/question");
                            break;
                        default:
                            this.lightbox.errorMsg = ErrorMessage[errNo];
                            this.lightbox.showLB = true;
                            this.doubleClick = false;
                            break;
                    }
                }
            }
        )
    }

    private closeLB() {
        this.lightbox.showLB = false;
    }
}